document.addEventListener('DOMContentLoaded', () => {
  const cookieBanner = document.querySelector('.cookie-message');
  // If hidecookiemessage isn't present then we want to handle the banner dismissal.
  if (!document.cookie.includes('hidecookiemessage=true')) {
    cookieBanner.classList.remove('cookie-message--hidden');
    const hideBannerButton = document.querySelector('.cookie-dismiss');
    hideBannerButton.addEventListener('click', () => {
      document.cookie = 'hidecookiemessage=true;path=/';
      cookieBanner.classList.add('cookie-message--hidden');
    });
  }
});
